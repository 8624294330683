.button {
	align-items: center;
	background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
	border: 0;
	box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
	box-sizing: border-box;
	color: #ffffff;
	display: flex;
	font-family: Phantomsans, sans-serif;
	justify-content: center;
	line-height: 1em;
	max-width: 100%;
	padding: 10px 10px;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;
}

.button1:hover {
	background-color: #af40ff;
}

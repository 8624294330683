.teamchart-card {
	height: auto;
	display: grid;
	align-items: center;
}
.tree {
	width: fit-content;
	height: auto;
	text-align: center;
}
.tree ul {
	padding-top: 20px;
	position: relative;
	transition: 0.5s;
}
.tree li {
	display: inline-table;
	text-align: center;
	list-style-type: none;
	position: relative;
	padding: 10px;
	transition: 0.5s;
}
.tree li:before,
.tree li:after {
	content: "";
	position: absolute;
	top: 0;
	right: 50%;
	/* border-top: 1px solid white; */
	width: 51%;
	height: 10px;
}

.tree li:after {
	right: auto;
	left: 50%;
	/* border-left: 1px solid white; */
}
.tree li:only-child:after,
.tree li:only-child::before {
	display: none;
}
.tree li:only-child {
	padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child:after {
	border: 0 none;
}

.tree li:last-child:before {
	/* border-right: 1px solid white; */
	border-radius: 0px 5px 0px 0px;
}

.tree ul ul:before {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	/* border-left: 1px solid white; */
	width: 0;
	height: 20px;
}
.tree div {
	border: 1px solid #ccc;
	padding: 10px;
	display: inline-grid;
	border-radius: 10px;
	text-decoration-line: none;
	transition: 0.5s;
}
.tree div img {
	width: 25px;
	height: 25px;
	margin-bottom: 10px !important;
	border-radius: 100px;
	margin: auto;
}

.tree div span {
	border-radius: 5px;
	white-space: break-spaces;
	padding: 8px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
}
.card {
	width: 150px;
	background-color: #A67FFB !important;
}
.tree-container {
	overflow-x: scroll;
	white-space: nowrap;
}

@media screen and (max-width: 768px) {
	.tree-container {
		overflow-x: scroll;
		white-space: nowrap;
	}
}

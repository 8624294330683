.swal2-popup.swal2-toast {
	background: #777777 !important;
	padding: 10px;
}

.swal2-popup.swal2-toast .swal2-title {
	color: #ffffffe3;
}

.swal2-timer-progress-bar-container {
	background-color: #ffffffe3 !important;
}

.monthcalendar .rbc-off-range-bg {
	background: none;
}

@media screen and (min-width: 240px) and (max-width: 540px) {
	.monthcalendar .rbc-toolbar {
		font-size: 8px;
	}
}

.rbc-btn-group {
	font-size: 1rem;
}

.rbc-btn-group button:nth-child(1) {
	background-color: silver;
}

.rbc-btn-group button:nth-child(2) {
	color: #a1a1aa;
}
.rbc-btn-group button:nth-child(3) {
	color: #a1a1aa;
}

.rbc-agenda-event-cell,
.rbc-agenda-time-cell,
.rbc-agenda-date-cell {
	color: white;
}
@media screen and (max-width: 480px) {
	.rbc-show-more {
		font-size: 56%;
	}
	.rbc-event-content {
		font-size: 6px;
	}
	.rbc-btn-group {
		font-size: 8px;
		margin-top: 10px;
	}
}

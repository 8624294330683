.loader-new {
	background-color: #010a11;
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
	background: white;
	border-radius: 50%;
	padding: 22px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
	content: "prev";
	font-size: 16px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
	content: "next";
	font-size: 16px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	z-index: 100;
	align-items: center;
}

.popup-inner {
	position: relative;
	/* top: -100px; */
	padding: 1px;
	max-width: 900px;
	max-height: 500px;
	overflow-y: auto;
	animation: popup-inner.2s;
	border-radius: 10px;
}

.popup-inner .close-btn {
	position: absolute;
	padding: 5px;
	border-radius: 50%;
	/* top: 16px; */
	right: 16px;
}

.header {
	position: sticky;
	top: 0;
	left: 0;
	padding: 15px;
	background-color: rgb(30, 58, 138);
	border-radius: 10px 10px 0 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	z-index: 999;
}

@keyframes popup-inner {
	0% {
		transform: scale(0.7);
	}
	45% {
		transform: scale(1.05);
	}
	80% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(1);
	}
}

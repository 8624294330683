.select-item:hover {
	color: black;
}

.rmsc .select-item {
	margin: 5px;
}

.rmsc .select-item.selected {
	color: black;
	background-color: #cbd5e1 !important;
}
.rmsc .search input:focus {
	background: none !important ;
}

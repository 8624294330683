@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins:ital,wght@0,200;0,300;0,500;0,600;0,700;0,800;1,700;1,800&family=Roboto+Condensed:ital,wght@0,400;0,700;1,300;1,400;1,700&family=Signika+Negative:wght@300;400;500;600;700&display=swap");

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	font-family: "Poppins", sans-serif;
	overflow-x: hidden;
	overflow-y: auto;
}

html,
body {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body::-webkit-scrollbar {
	width: 0.4em;
	border-radius: 50vh;
	cursor: pointer;
}

/* .popup-inner::-webkit-scrollbar {
	 width: 0px; 
	border-radius: 50vh;
	cursor: pointer;
} */

body::-webkit-scrollbar-track,
.popup-inner::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: rgb(211, 27, 39);
	outline: none;
}

/* .popup-inner::-webkit-scrollbar-thumb {
	background-color: transparent;
	outline: none;
} */

/* Responsive Font Sizes */

/* Paragraphs */
p {
	font-size: 16px;
	line-height: 1.5;
}

/* Headings */
h1 {
	font-size: 32px;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

/* Links */
a {
	font-size: 16px;
}

/* List Items */
li {
	font-size: 16px;
}

/* Responsive Font Sizes - Media Queries */

/* Adjust font sizes for smaller screens */
@media (max-width: 768px) {
	/* Paragraphs */
	p {
		font-size: 14px;
	}

	/* Headings */
	h1 {
		font-size: 28px;
	}

	h2 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 18px;
	}

	h5 {
		font-size: 16px;
	}

	h6 {
		font-size: 14px;
	}

	/* Links */
	a {
		font-size: 14px;
	}

	/* List Items */
	li {
		font-size: 14px;
	}
}

/* Adjust font sizes for even smaller screens */
@media (max-width: 480px) {
	/* Paragraphs */
	p {
		font-size: 12px;
	}

	/* Headings */
	h1 {
		font-size: 24px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	h4 {
		font-size: 16px;
	}

	h5 {
		font-size: 14px;
	}

	h6 {
		font-size: 12px;
	}

	/* Links */
	a {
		font-size: 12px;
	}

	/* List Items */
	li {
		font-size: 12px;
	}
}

.bg-cardFiveBg {
	background-color: #ff0000db !important;
}

.scrollbar-none::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.scrollbar-none {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

* > .intro-x:nth-child(1) {
	z-index: 49;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.1s;
}

* > .-intro-x:nth-child(1) {
	z-index: 49;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.1s;
}

* > .intro-y:nth-child(1) {
	z-index: 49;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.1s;
}

* > .-intro-y:nth-child(1) {
	z-index: 49;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.1s;
}

* > .intro-x:nth-child(2) {
	z-index: 48;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

* > .-intro-x:nth-child(2) {
	z-index: 48;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

* > .intro-y:nth-child(2) {
	z-index: 48;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

* > .-intro-y:nth-child(2) {
	z-index: 48;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.2s;
}

* > .intro-x:nth-child(3) {
	z-index: 47;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(3 * 0.1s);
}

* > .-intro-x:nth-child(3) {
	z-index: 47;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(3 * 0.1s);
}

* > .intro-y:nth-child(3) {
	z-index: 47;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(3 * 0.1s);
}

* > .-intro-y:nth-child(3) {
	z-index: 47;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(3 * 0.1s);
}

* > .intro-x:nth-child(4) {
	z-index: 46;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.4s;
}

* > .-intro-x:nth-child(4) {
	z-index: 46;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.4s;
}

* > .intro-y:nth-child(4) {
	z-index: 46;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.4s;
}

* > .-intro-y:nth-child(4) {
	z-index: 46;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.4s;
}

* > .intro-x:nth-child(5) {
	z-index: 45;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

* > .-intro-x:nth-child(5) {
	z-index: 45;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

* > .intro-y:nth-child(5) {
	z-index: 45;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

* > .-intro-y:nth-child(5) {
	z-index: 45;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
}

* > .intro-x:nth-child(6) {
	z-index: 44;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(6 * 0.1s);
}

* > .-intro-x:nth-child(6) {
	z-index: 44;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(6 * 0.1s);
}

* > .intro-y:nth-child(6) {
	z-index: 44;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(6 * 0.1s);
}

* > .-intro-y:nth-child(6) {
	z-index: 44;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(6 * 0.1s);
}

* > .intro-x:nth-child(7) {
	z-index: 43;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(7 * 0.1s);
}

* > .-intro-x:nth-child(7) {
	z-index: 43;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(7 * 0.1s);
}

* > .intro-y:nth-child(7) {
	z-index: 43;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(7 * 0.1s);
}

* > .-intro-y:nth-child(7) {
	z-index: 43;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(7 * 0.1s);
}

* > .intro-x:nth-child(8) {
	z-index: 42;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.8s;
}

* > .-intro-x:nth-child(8) {
	z-index: 42;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.8s;
}

* > .intro-y:nth-child(8) {
	z-index: 42;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.8s;
}

* > .-intro-y:nth-child(8) {
	z-index: 42;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.8s;
}

* > .intro-x:nth-child(9) {
	z-index: 41;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.9s;
}

* > .-intro-x:nth-child(9) {
	z-index: 41;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.9s;
}

* > .intro-y:nth-child(9) {
	z-index: 41;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.9s;
}

* > .-intro-y:nth-child(9) {
	z-index: 41;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 0.9s;
}

* > .intro-x:nth-child(10) {
	z-index: 40;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

* > .-intro-x:nth-child(10) {
	z-index: 40;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

* > .intro-y:nth-child(10) {
	z-index: 40;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

* > .-intro-y:nth-child(10) {
	z-index: 40;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

* > .intro-x:nth-child(11) {
	z-index: 39;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.1s;
}

* > .-intro-x:nth-child(11) {
	z-index: 39;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.1s;
}

* > .intro-y:nth-child(11) {
	z-index: 39;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.1s;
}

* > .-intro-y:nth-child(11) {
	z-index: 39;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.1s;
}

* > .intro-x:nth-child(12) {
	z-index: 38;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(12 * 0.1s);
}

* > .-intro-x:nth-child(12) {
	z-index: 38;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(12 * 0.1s);
}

* > .intro-y:nth-child(12) {
	z-index: 38;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(12 * 0.1s);
}

* > .-intro-y:nth-child(12) {
	z-index: 38;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(12 * 0.1s);
}

* > .intro-x:nth-child(13) {
	z-index: 37;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.3s;
}

* > .-intro-x:nth-child(13) {
	z-index: 37;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.3s;
}

* > .intro-y:nth-child(13) {
	z-index: 37;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.3s;
}

* > .-intro-y:nth-child(13) {
	z-index: 37;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.3s;
}

* > .intro-x:nth-child(14) {
	z-index: 36;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(14 * 0.1s);
}

* > .-intro-x:nth-child(14) {
	z-index: 36;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(14 * 0.1s);
}

* > .intro-y:nth-child(14) {
	z-index: 36;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(14 * 0.1s);
}

* > .-intro-y:nth-child(14) {
	z-index: 36;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(14 * 0.1s);
}

* > .intro-x:nth-child(15) {
	z-index: 35;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.5s;
}

* > .-intro-x:nth-child(15) {
	z-index: 35;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.5s;
}

* > .intro-y:nth-child(15) {
	z-index: 35;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.5s;
}

* > .-intro-y:nth-child(15) {
	z-index: 35;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.5s;
}

* > .intro-x:nth-child(16) {
	z-index: 34;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.6s;
}

* > .-intro-x:nth-child(16) {
	z-index: 34;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.6s;
}

* > .intro-y:nth-child(16) {
	z-index: 34;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.6s;
}

* > .-intro-y:nth-child(16) {
	z-index: 34;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.6s;
}

* > .intro-x:nth-child(17) {
	z-index: 33;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(17 * 0.1s);
}

* > .-intro-x:nth-child(17) {
	z-index: 33;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(17 * 0.1s);
}

* > .intro-y:nth-child(17) {
	z-index: 33;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(17 * 0.1s);
}

* > .-intro-y:nth-child(17) {
	z-index: 33;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(17 * 0.1s);
}

* > .intro-x:nth-child(18) {
	z-index: 32;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.8s;
}

* > .-intro-x:nth-child(18) {
	z-index: 32;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.8s;
}

* > .intro-y:nth-child(18) {
	z-index: 32;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.8s;
}

* > .-intro-y:nth-child(18) {
	z-index: 32;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 1.8s;
}

* > .intro-x:nth-child(19) {
	z-index: 31;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(19 * 0.1s);
}

* > .-intro-x:nth-child(19) {
	z-index: 31;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(19 * 0.1s);
}

* > .intro-y:nth-child(19) {
	z-index: 31;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(19 * 0.1s);
}

* > .-intro-y:nth-child(19) {
	z-index: 31;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(19 * 0.1s);
}

* > .intro-x:nth-child(20) {
	z-index: 30;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2s;
}

* > .-intro-x:nth-child(20) {
	z-index: 30;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2s;
}

* > .intro-y:nth-child(20) {
	z-index: 30;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2s;
}

* > .-intro-y:nth-child(20) {
	z-index: 30;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2s;
}

* > .intro-x:nth-child(21) {
	z-index: 29;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.1s;
}

* > .-intro-x:nth-child(21) {
	z-index: 29;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.1s;
}

* > .intro-y:nth-child(21) {
	z-index: 29;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.1s;
}

* > .-intro-y:nth-child(21) {
	z-index: 29;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.1s;
}

* > .intro-x:nth-child(22) {
	z-index: 28;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.2s;
}

* > .-intro-x:nth-child(22) {
	z-index: 28;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.2s;
}

* > .intro-y:nth-child(22) {
	z-index: 28;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.2s;
}

* > .-intro-y:nth-child(22) {
	z-index: 28;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.2s;
}

* > .intro-x:nth-child(23) {
	z-index: 27;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(23 * 0.1s);
}

* > .-intro-x:nth-child(23) {
	z-index: 27;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(23 * 0.1s);
}

* > .intro-y:nth-child(23) {
	z-index: 27;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(23 * 0.1s);
}

* > .-intro-y:nth-child(23) {
	z-index: 27;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(23 * 0.1s);
}

* > .intro-x:nth-child(24) {
	z-index: 26;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(24 * 0.1s);
}

* > .-intro-x:nth-child(24) {
	z-index: 26;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(24 * 0.1s);
}

* > .intro-y:nth-child(24) {
	z-index: 26;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(24 * 0.1s);
}

* > .-intro-y:nth-child(24) {
	z-index: 26;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(24 * 0.1s);
}

* > .intro-x:nth-child(25) {
	z-index: 25;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.5s;
}

* > .-intro-x:nth-child(25) {
	z-index: 25;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.5s;
}

* > .intro-y:nth-child(25) {
	z-index: 25;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.5s;
}

* > .-intro-y:nth-child(25) {
	z-index: 25;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.5s;
}

* > .intro-x:nth-child(26) {
	z-index: 24;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.6s;
}

* > .-intro-x:nth-child(26) {
	z-index: 24;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.6s;
}

* > .intro-y:nth-child(26) {
	z-index: 24;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.6s;
}

* > .-intro-y:nth-child(26) {
	z-index: 24;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.6s;
}

* > .intro-x:nth-child(27) {
	z-index: 23;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.7s;
}

* > .-intro-x:nth-child(27) {
	z-index: 23;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.7s;
}

* > .intro-y:nth-child(27) {
	z-index: 23;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.7s;
}

* > .-intro-y:nth-child(27) {
	z-index: 23;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 2.7s;
}

* > .intro-x:nth-child(28) {
	z-index: 22;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(28 * 0.1s);
}

* > .-intro-x:nth-child(28) {
	z-index: 22;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(28 * 0.1s);
}

* > .intro-y:nth-child(28) {
	z-index: 22;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(28 * 0.1s);
}

* > .-intro-y:nth-child(28) {
	z-index: 22;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(28 * 0.1s);
}

* > .intro-x:nth-child(29) {
	z-index: 21;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(29 * 0.1s);
}

* > .-intro-x:nth-child(29) {
	z-index: 21;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(29 * 0.1s);
}

* > .intro-y:nth-child(29) {
	z-index: 21;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(29 * 0.1s);
}

* > .-intro-y:nth-child(29) {
	z-index: 21;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(29 * 0.1s);
}

* > .intro-x:nth-child(30) {
	z-index: 20;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3s;
}

* > .-intro-x:nth-child(30) {
	z-index: 20;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3s;
}

* > .intro-y:nth-child(30) {
	z-index: 20;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3s;
}

* > .-intro-y:nth-child(30) {
	z-index: 20;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3s;
}

* > .intro-x:nth-child(31) {
	z-index: 19;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.1s;
}

* > .-intro-x:nth-child(31) {
	z-index: 19;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.1s;
}

* > .intro-y:nth-child(31) {
	z-index: 19;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.1s;
}

* > .-intro-y:nth-child(31) {
	z-index: 19;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.1s;
}

* > .intro-x:nth-child(32) {
	z-index: 18;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.2s;
}

* > .-intro-x:nth-child(32) {
	z-index: 18;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.2s;
}

* > .intro-y:nth-child(32) {
	z-index: 18;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.2s;
}

* > .-intro-y:nth-child(32) {
	z-index: 18;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.2s;
}

* > .intro-x:nth-child(33) {
	z-index: 17;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(33 * 0.1s);
}

* > .-intro-x:nth-child(33) {
	z-index: 17;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(33 * 0.1s);
}

* > .intro-y:nth-child(33) {
	z-index: 17;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(33 * 0.1s);
}

* > .-intro-y:nth-child(33) {
	z-index: 17;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(33 * 0.1s);
}

* > .intro-x:nth-child(34) {
	z-index: 16;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(34 * 0.1s);
}

* > .-intro-x:nth-child(34) {
	z-index: 16;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(34 * 0.1s);
}

* > .intro-y:nth-child(34) {
	z-index: 16;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(34 * 0.1s);
}

* > .-intro-y:nth-child(34) {
	z-index: 16;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(34 * 0.1s);
}

* > .intro-x:nth-child(35) {
	z-index: 15;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.5s;
}

* > .-intro-x:nth-child(35) {
	z-index: 15;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.5s;
}

* > .intro-y:nth-child(35) {
	z-index: 15;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.5s;
}

* > .-intro-y:nth-child(35) {
	z-index: 15;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.5s;
}

* > .intro-x:nth-child(36) {
	z-index: 14;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.6s;
}

* > .-intro-x:nth-child(36) {
	z-index: 14;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.6s;
}

* > .intro-y:nth-child(36) {
	z-index: 14;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.6s;
}

* > .-intro-y:nth-child(36) {
	z-index: 14;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.6s;
}

* > .intro-x:nth-child(37) {
	z-index: 13;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.7s;
}

* > .-intro-x:nth-child(37) {
	z-index: 13;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.7s;
}

* > .intro-y:nth-child(37) {
	z-index: 13;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.7s;
}

* > .-intro-y:nth-child(37) {
	z-index: 13;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 3.7s;
}

* > .intro-x:nth-child(38) {
	z-index: 12;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(38 * 0.1s);
}

* > .-intro-x:nth-child(38) {
	z-index: 12;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(38 * 0.1s);
}

* > .intro-y:nth-child(38) {
	z-index: 12;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(38 * 0.1s);
}

* > .-intro-y:nth-child(38) {
	z-index: 12;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(38 * 0.1s);
}

* > .intro-x:nth-child(39) {
	z-index: 11;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(39 * 0.1s);
}

* > .-intro-x:nth-child(39) {
	z-index: 11;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(39 * 0.1s);
}

* > .intro-y:nth-child(39) {
	z-index: 11;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(39 * 0.1s);
}

* > .-intro-y:nth-child(39) {
	z-index: 11;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(39 * 0.1s);
}

* > .intro-x:nth-child(40) {
	z-index: 10;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4s;
}

* > .-intro-x:nth-child(40) {
	z-index: 10;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4s;
}

* > .intro-y:nth-child(40) {
	z-index: 10;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4s;
}

* > .-intro-y:nth-child(40) {
	z-index: 10;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4s;
}

* > .intro-x:nth-child(41) {
	z-index: 9;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(41 * 0.1s);
}

* > .-intro-x:nth-child(41) {
	z-index: 9;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(41 * 0.1s);
}

* > .intro-y:nth-child(41) {
	z-index: 9;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(41 * 0.1s);
}

* > .-intro-y:nth-child(41) {
	z-index: 9;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(41 * 0.1s);
}

* > .intro-x:nth-child(42) {
	z-index: 8;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.2s;
}

* > .-intro-x:nth-child(42) {
	z-index: 8;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.2s;
}

* > .intro-y:nth-child(42) {
	z-index: 8;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.2s;
}

* > .-intro-y:nth-child(42) {
	z-index: 8;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.2s;
}

* > .intro-x:nth-child(43) {
	z-index: 7;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.3s;
}

* > .-intro-x:nth-child(43) {
	z-index: 7;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.3s;
}

* > .intro-y:nth-child(43) {
	z-index: 7;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.3s;
}

* > .-intro-y:nth-child(43) {
	z-index: 7;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.3s;
}

* > .intro-x:nth-child(44) {
	z-index: 6;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.4s;
}

* > .-intro-x:nth-child(44) {
	z-index: 6;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.4s;
}

* > .intro-y:nth-child(44) {
	z-index: 6;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.4s;
}

* > .-intro-y:nth-child(44) {
	z-index: 6;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.4s;
}

* > .intro-x:nth-child(45) {
	z-index: 5;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.5s;
}

* > .-intro-x:nth-child(45) {
	z-index: 5;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.5s;
}

* > .intro-y:nth-child(45) {
	z-index: 5;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.5s;
}

* > .-intro-y:nth-child(45) {
	z-index: 5;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.5s;
}

* > .intro-x:nth-child(46) {
	z-index: 4;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(46 * 0.1s);
}

* > .-intro-x:nth-child(46) {
	z-index: 4;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(46 * 0.1s);
}

* > .intro-y:nth-child(46) {
	z-index: 4;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(46 * 0.1s);
}

* > .-intro-y:nth-child(46) {
	z-index: 4;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(46 * 0.1s);
}

* > .intro-x:nth-child(47) {
	z-index: 3;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.7s;
}

* > .-intro-x:nth-child(47) {
	z-index: 3;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.7s;
}

* > .intro-y:nth-child(47) {
	z-index: 3;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.7s;
}

* > .-intro-y:nth-child(47) {
	z-index: 3;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.7s;
}

* > .intro-x:nth-child(48) {
	z-index: 2;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(48 * 0.1s);
}

* > .-intro-x:nth-child(48) {
	z-index: 2;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(48 * 0.1s);
}

* > .intro-y:nth-child(48) {
	z-index: 2;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(48 * 0.1s);
}

* > .-intro-y:nth-child(48) {
	z-index: 2;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: calc(48 * 0.1s);
}

* > .intro-x:nth-child(49) {
	z-index: 1;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.9s;
}

* > .-intro-x:nth-child(49) {
	z-index: 1;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.9s;
}

* > .intro-y:nth-child(49) {
	z-index: 1;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.9s;
}

* > .-intro-y:nth-child(49) {
	z-index: 1;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 4.9s;
}

* > .intro-x:nth-child(50) {
	z-index: 0;
	opacity: 0;
	position: relative;
	transform: translate(50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 5s;
}

* > .-intro-x:nth-child(50) {
	z-index: 0;
	opacity: 0;
	position: relative;
	transform: translate(-50px);
	animation: 0.4s intro-x-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 5s;
}

* > .intro-y:nth-child(50) {
	z-index: 0;
	opacity: 0;
	position: relative;
	transform: translateY(50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 5s;
}

* > .-intro-y:nth-child(50) {
	z-index: 0;
	opacity: 0;
	position: relative;
	transform: translateY(-50px);
	animation: 0.4s intro-y-animation ease-in-out 0.33333s;
	animation-fill-mode: forwards;
	animation-delay: 5s;
}

@keyframes intro-x-animation {
	to {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes intro-y-animation {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@tailwind base;
@tailwind components;
@tailwind utilities;
